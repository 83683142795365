import { Component, OnInit } from '@angular/core';
import { ConfigSettings } from 'src/app/models/models';

@Component({
    selector: 'app-root',
    templateUrl: './config.component.html'
})
export class ConfigComponent implements OnInit {
    public settings = new ConfigSettings();

    title = 'config-page';

    ngOnInit(): void {
        let data = (document.querySelector("#JsonSettings") as HTMLInputElement).value;

        if (data !== "")
            this.settings = JSON.parse(data);
        else
            this.settings = new ConfigSettings();
    }

    public serializeSettings(): void {
        var input = document.querySelector("#JsonSettings") as HTMLInputElement;
        input.value = JSON.stringify(this.settings);
    }
}