import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPartSettingsService } from './services/ipart-settings.service';
import { Subscription } from 'rxjs';
import { Helper } from './utils/helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  // subscriptions
  settingsSubscription: Subscription;
  // view data
  title = 'ESCbreadcrumb';
  data: string[] = [];
  parentItem='My Account';
  homeurl = (document.getElementById('x-home') as HTMLInputElement).value;
  myaccurl= (document.getElementById('x-myaccount') as HTMLInputElement).value;

  constructor(private iPartSettingsService: IPartSettingsService) {
    Helper.unSubscribeIfActive(this.settingsSubscription);
    this.settingsSubscription = this.iPartSettingsService.GetSettings().subscribe(data => {
     console.log(data);

     if(data!=null){
             for (let [key, value] of Object.entries(data)) {
               console.log(key);
              if(`${key}`=="breadcrumb"){
                var d = `${value}`;
               
                if(d!=""){
                 
                  var menu = d.split('>');
                    
                  for(let item of menu){
                  this.data.push(`${item}`);
                  }
                }
                else{

     

                  var breadcrumbHolder = document.getElementById("breadcrumbHolder");      
                  var path = window.location.pathname;
                  var breadcrumb = path.substring(path.lastIndexOf('/') + 1);
                  breadcrumb = breadcrumb.replace("ESC/", "");
            
                  breadcrumb = breadcrumb.replace("_", " ");
                  breadcrumb = breadcrumb.replace("_", " ");
                  breadcrumb = breadcrumb.replace("_", " ");
                  breadcrumb = breadcrumb.replace(".aspx", "");
                  breadcrumb = breadcrumb.replace("/", " > ");
                  breadcrumb = breadcrumb.replace("/", " > ");
                  breadcrumb = breadcrumb.replace("/", " > ");
            
                 // breadcrumbHolder.innerHTML = breadcrumb;
                  console.log(breadcrumb);
                  this.data.push(breadcrumb)
            
                /*
                  var menu2 = breadcrumb.split('>');
                  for(let item of menu2){
                    this.data.push(`${item}`);
                    }
                  */
            
                }
                    
                }
                if(`${key}`=="parentItem"){
                  if(`${value}`!=""){
                    this.parentItem =`${value}`;
                  }
                 
            }

          }
    }
   
    });
  }

  ngOnInit(): void {


    
  }

  ngOnDestroy(): void {
    Helper.unSubscribeIfActive(this.settingsSubscription);
  }
}
