import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { ApiService } from './services/api.service';
import { ConfigComponent } from './components/config/config.component';
import { ContentKeysService } from './services/content-keys.service';
import { IPartSettingsService } from './services/ipart-settings.service';

@NgModule({
  declarations: [
    AppComponent,
    ConfigComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [ContentKeysService, IPartSettingsService, ApiService],
  entryComponents: [AppComponent, ConfigComponent]
})
export class AppModule {
  ngDoBootstrap(appRef: ApplicationRef) {
    let bootstrapComponent = (<HTMLInputElement>document.getElementById("bootstrapComponent"));

    if (bootstrapComponent != null && bootstrapComponent.value == "Config") {
      appRef.bootstrap(ConfigComponent);
    } else {
      // default
      appRef.bootstrap(AppComponent);
    }
  }
}
